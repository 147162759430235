import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Combobox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon, MapPinIcon, PhoneIcon } from '@heroicons/react/20/solid';
import { estimators } from '../utils/State';
import { updateCalendly, updateEstimator } from '../utils/Actions';
import { classNames } from '../utils/helpers';

const ListEstimator = () => {

    const stateEstimator = useSelector(state => state.estimator);
    const dispatch = useDispatch();
    const [query, setQuery] = useState('');

    const filteredPeople =
        query === ''
            ? estimators
            : estimators.filter((person) => {
                return person.name.toLowerCase().includes(query.toLowerCase());
            });

    return (
        <Combobox
            as="div"
            value={stateEstimator}
            onChange={(person) => {
                setQuery('');
                if (!person?.calendar?.GTA) dispatch(updateCalendly(false)); // revert to form if estimator does not have calendly setup
                dispatch(updateEstimator(person));
            }}
        >
            <Combobox.Label className="block text-sm font-medium leading-6 text-gray-90">Assign to</Combobox.Label>
            <div className="relative mt-2">
                <Combobox.Input
                    className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-green-700 sm:text-sm sm:leading-6"
                    onChange={(event) => setQuery(event.target.value)}
                    displayValue={(person) => person?.name}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <ChevronUpDownIcon className="size-5 text-gray-400" aria-hidden="true" />
                </Combobox.Button>

                {filteredPeople.length > 0 && (
                    <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredPeople.map((person) => (
                            <Combobox.Option
                                key={person.id}
                                value={person}
                                className={({ active }) =>
                                    classNames(
                                        'relative cursor-default select-none py-2 pl-8 pr-4',
                                        active ? 'bg-green-700 text-white' : 'text-gray-900'
                                    )
                                }
                            >

                                {({ active, selected }) => (
                                    <>
                                        <span className={classNames('block truncate', selected && 'font-semibold')}>{person.name}</span>

                                        {selected && (
                                            <span
                                                className={classNames(
                                                    'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                                    active ? 'text-white' : 'text-green-700'
                                                )}
                                            >
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                        )}
                                    </>
                                )}

                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                )}
            </div>
        </Combobox>
    )
};


export default ListEstimator;
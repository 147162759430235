import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    updateLead,
    updateCalendlyUrl,
    updateCalendly
} from '../utils/Actions';
import { getEnv } from '../utils/helpers';

const decodeParamsValue = (encodedValue) => { // that function decodes the URL parameters | to -
    return encodedValue.replace(/\|/g, '-');
};

const DecodeParams = () => { // that component decodes the URL parameters and updates the state

    const estimator = useSelector(state => state.estimator);

    const dispatch = useDispatch();

    const navigate = useNavigate(); // that hook is used to navigate to the home page after the state is updated

    let { fill } = useParams();

    useEffect(() => {
        const abc = async () => {
            const paramsArray = fill.split('-').map(decodeParamsValue);
            const params = {
                firstName: paramsArray?.[0] ?? '',
                lastName: paramsArray?.[1] ?? '',
                phone: paramsArray?.[2] ?? '',
                email: paramsArray?.[3] ?? '',
                country: 'Canada',
                streetAddress: paramsArray?.[4] ?? '',
                city: paramsArray?.[5] ?? '',
                region: paramsArray?.[6] ?? '',
                postalCode: paramsArray?.[7] ?? '',
                audience: {
                    curbing: Boolean(paramsArray?.[8]),
                    resurfacing: Boolean(paramsArray?.[9]),
                    grass: Boolean(paramsArray?.[10]),
                    ottawa: Boolean(paramsArray?.[11]),
                    golf: Boolean(paramsArray?.[12]),
                    municipality: Boolean(paramsArray?.[13])
                },
                comment: paramsArray?.[14] ?? ''
            };
            const startCalendly = Boolean(paramsArray?.[15]);
            dispatch(updateLead(params)); //update the state with the lead parameters
            if (startCalendly && estimator?.calendar?.GTA) dispatch(updateCalendly(true)) // display calendly after the redirect
                else dispatch(updateCalendly(false)); 
            navigate('/', { replace: true });
        };
        abc();
    });
    return null;
};

export default DecodeParams;
import React, { useState } from 'react';
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { useSelector, useDispatch } from 'react-redux';
import {
    updateMessage,
    updateLead,
    updateCalendly,
    updateCalendlyUrl
} from '../utils/Actions';
import { getKey } from '../utils/helpers';
import { XMarkIcon } from '@heroicons/react/24/outline'

const emailConfirm = async (lead) => {
    try {
        await fetch('/api/appointment', { // email the lead data
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': getKey() // this is for protection of the server route
            },
            body: JSON.stringify(lead),
        });

    } catch (err) {
        console.error(err);
    }
};

const Calendly = () => {

    const lead = useSelector(state => state.lead);
    const estimator = useSelector(state => state.estimator);
    const dummyLead = useSelector(state => state.dummyLead);
    const dispatch = useDispatch();
    const urli = useSelector(state => state.estimator.calendar.GTA);

    useCalendlyEventListener({
        /*onProfilePageViewed: () => console.log("onProfilePageViewed"),
        onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
        onEventTypeViewed: () => console.log("onEventTypeViewed"), */
        onEventScheduled: (e) => {
            //console.log(e.data.payload);
            emailConfirm({ ...lead, estimator: estimator.name }); // backend to send email that user scheduled an appointment
            dispatch(updateMessage('Confirmation', 'Thank you - your preference for date and time of estimate has been recorded.'));
            dispatch(updateLead(dummyLead)); // clear state for the next input
            dispatch(updateCalendly(false)); // hide calendly
        },
    });

    const handleClose = () => {
        dispatch(updateLead(dummyLead)); // clear state for the next input
        dispatch(updateCalendly(false)); // hide calendly
    };


    return (
        <>
            <XMarkIcon
                className="w-8 h-8 cursor-pointer text-gray-600 hover:text-gray-800"
                onClick={handleClose} // Call the function when clicked
            />
            {urli &&
                (<div className="Calendly">
                    <InlineWidget
                        /*rootElement={document.getElementById("root")} 
                        text="Schedule in Calendar" */
                        url={urli}
                        textColor="#ffffff"
                        color="#047857"
                        prefill={{
                            email: lead.email,
                            //firstName: lead.firstName,
                            //lastName: lead.lastName,
                            name: lead.firstName + ' ' + lead.lastName,
                            customAnswers: {
                                a1: lead.streetAddress + ' ' + lead.city + ' ' + lead.region + ' ' + lead.postalCode,
                                a2: lead.comment
                            }
                        }}
                    />
                </div>)
            }
        </>
    );
};

export default Calendly;